<template >
    <div>
        <h1>Under konstruktion</h1>
        <h2>Kommer finnas lite grundläggande boknings information här</h2>
    </div>
</template>
<script>
export default {
    name: "Stats"
}
</script>
<style lang="">
    
</style>