<template>
    <div class="container">
        <h2>Skapa tider för bokningar</h2>
        <p>Här går det att schedulera tider som går att boka för kunder</p>
        <p>Välj ett datum och sedan mellan vilka tider på dygnet du vill skapa tider på. Välj sedan ett intervall mellan varje tid som görs bokningsbar och tryck på generera tider för att se vilken tider som kommer göra publika</p>
        <div class="row">
            <div class="col">
                <div>
                    <label for="example-datepicker">Välj ett datum
                        <i class="fa fa-info-circle" aria-hidden="true"
                            v-b-popover.hover="'Här kan du välja ett datum för att redigera tider på. Antigen genom att lägga upp nya tider eller se tider som redan finns.'"
                            title="Datum"></i>
                    </label>
                    <b-form-datepicker :locale="locale" labelNoDateSelected="Välj ett datum" id="example-datepicker" v-model="date" class="mb-2"></b-form-datepicker>
                </div>
            </div>
                <div class="col">
                    <label for="example-input">Välj start tid
                        <i class="fa fa-info-circle" aria-hidden="true"
                            v-b-popover.hover="'Här kan väljer du en starttid för dagen, t.ex om göra det möjligt för kunder att boka från 08:00 så väljer du denna tid. Det går även att skriva in tid manuelt i textrutan'"
                            title="Start tid"></i>
                    </label>
                    <b-input-group class="mb-3">
                    <b-form-input
                        id="example-input"
                        v-model="startTime"
                        type="text"
                        placeholder="HH:mm"
                        :state="startFilled"
                    ></b-form-input>
                    <b-input-group-append>
                        <b-form-timepicker
                        v-model="startTime"
                        button-only
                        right
                        locale="en"
                        aria-controls="example-input"
                        minutes-step="5"
                        :hour12="false"
                        asdfasdf
                        ></b-form-timepicker>
                    </b-input-group-append>
                    </b-input-group>
                </div>
                <div class="col">
                    <label for="example-input">Välj slut tid
                        <i class="fa fa-info-circle" aria-hidden="true"
                            v-b-popover.hover="'Här kan väljer du en sluttid för dagen, t.ex ska dagen ta slut 13:00 så väljer du det. Det går även att skriva in tid manuelt i textrutan'"
                            title="Start tid"></i>
                    </label>
                    <b-input-group class="mb-3">
                    <b-form-input
                        id="example-input"
                        v-model="endTime"
                        type="text"
                        placeholder="HH:mm"
                        :state="endFilled"
                    ></b-form-input>
                    <b-input-group-append>
                        <b-form-timepicker
                        v-model="endTime"
                        button-only
                        right
                        locale="en"
                        aria-controls="example-input"
                        minutes-step="5"
                        :hour12="false"
                    
                        ></b-form-timepicker>
                    </b-input-group-append>
                    </b-input-group>
                
                </div>
            
        </div>
        <div class="row">
            <div class="text-center">
                <label for="demo-sb">Önskat intervall i minuter
                    <i class="fa fa-info-circle" aria-hidden="true"
                            v-b-popover.hover="'Här väljer du intervallet för varje bokningsbart tillfälle.'"
                            title="Intervall"></i>
                </label>
                <b-form-spinbutton id="demo-sb" v-model="interval" min="0" max="100" step="5" placeholder="mm"></b-form-spinbutton>
            </div>
        </div>
        <div class="row pt-4">
            <b-button block variant="primary" v-on:click="generateTimes()">Generera tider</b-button>
        </div>
        <h2>Genererade tider</h2>
        <p v-if="timeSlots.length != 0">Du har genererat {{this.timeSlots.length}} tider från {{timeSlots[0]}} 
            till {{timeSlots[timeSlots.length - 1]}} med {{interval}}min intervall
        </p>
        <div class="row">
            <b-button v-if="timeControls" block variant="danger" v-on:click="removeAllTimes()">Ta bort alla tider</b-button>
            <b-table striped ref="table" class="col" :fields="fields" :items="items" :busy="this.$store.getters.getDatabaseLoadingStatus">
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
                <template #cell(Datum)="data">
                    {{ data.item.datum }}
                    
                </template>
                <template #cell(Tid)="data">
                    {{ data.item.tid }}                        
                </template>
                <template #cell(del)="data">
                        <b-button v-on:click="removeTime(data.item.tid)"
                            v-b-popover.hover.v-danger.ds900="{ content: 'Tar du bort en tid på en dag som du redan publicerat tider på så kommer alla bokade tider samma dag kunnas boka igen!' }"
                            title="Om det redan finns tider denna dag"
                            >
                            <i class="fa fa-times" aria-hidden="true"></i>
                            
                        </b-button>
                </template>
                <template #cell(booked)="data">
                    <i v-on:click="bookTime(data.item.tid)" 
                        :class="[data.item.bokad ? 'fa fa-toggle-on fa-2x': 'fa fa-toggle-off fa-2x']" 
                        aria-hidden="true" 
                        v-bind:style="data.item.bokad ? 'color:red;' : 'color:green;' "
                        v-b-popover.hover.v-danger.ds500="{ content: 'Denna knapp bokar eller avbokar tiden, var försikt om kund redan har bokat! Trycker du av misstag är det bara att trycka på knappen igen. OBS detta visas i realtid på hemsidan, sitter du alltså och leker så kommer kunder kunna se detta' }"
                            title="Sätt bokningsstatus för tid"></i>
                </template>
            </b-table>
        </div>
        <div class="row justify-content-md-center">
            <b-alert
                v-if="!errorText"
                :show="dismissCountDown"    
                dismissible
                fade
                variant="success"
                @dismissed="dismissCountDown=0"
                @dismiss-count-down="countDownChanged"
                >
                <h4 class="alert-heading">Tiderna publiserades!</h4>
                <p>
                Rutan stängs automatiskt om {{dismissCountDown}} sekunder... 
                </p>
                <hr v-if="errorText">
                <p class="mb-0">{{errorText}}</p>
            </b-alert>
            <b-alert
                v-else-if="errorText"
                :show="showDismissableAlert"
                dismissible
                fade
                variant="danger"
                @dismissed="showDismissableAlert=false"
                >
                <h4 class="alert-heading">Något gick snett!</h4>
                <p>
                Försök igen om några sekunder, ifall fel kvarstår kontakta system administratör.
                </p>
                <hr>
                <p class="mb-0">Typ av fel: {{errorText}}</p>
            </b-alert>
        </div>
        <div class="row">
            <b-button v-if="timeControls || booking && !publishLoading" block v-on:click="submitForm()">Publicera tider</b-button>
            <b-button v-else-if="timeControls || booking && publishLoading" block v-on:click="submitForm()"><b-spinner small></b-spinner> Laddar...</b-button>
        </div>
    </div>
</template>
<script>
// import Toolbar from "@/components/Toolbar.vue";
const moment = require('moment')
// import { addZeroToDate } from '@/dateManipulation';
import { auth } from "../api/firebase";
export default {
    name: 'Booking',
    components: {
        // Toolbar,
    },
    data() {
        return {
            date: null,
            startTime: null,
            startFilled: false,
            endTime: null,
            endFilled: false,
            interval: null,
            timeSlots: [],
            items:[],
            formattedDate: null,
            publishType: "normal",
            timezone: '',
            publishLoading: false,
            fields: [
                'Datum',
                'Tid',
                { key:'booked', label:'Bokad'},
                { key:'del', label: 'Ta bort tid'}
            ],
            timeControls: false,
            booking: [],
            dismissSecs: 10,
            dismissCountDown: 0,
            showDismissableAlert: false,
            errorText: null,
            locale: 'sv',
        };
    },
    methods: {
        test(){
            
        },
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        generateTimes(){
            if(this.interval == null) {
                alert("Välj ett intervall")
                return;
            }
            
            let s = moment(this.date + " " + this.startTime);
            let e = moment(this.date + " " + this.endTime);
            this.timeSlots = [];
            let currentTime = s;
            while(s <= e){
                this.timeSlots.push(currentTime.format('HH:mm').toString());
                
                this.items.push({
                    'datum': this.date,
                    'tid': currentTime.format('HH:mm').toString()
                });
                currentTime.add(this.interval, 'm');
            }
            this.timeControls = true;
            
            
            
            
        },
        async submitForm(){
            let details = this.createJSON()
            this.publishLoading = true;
            
            let idToken = await auth().currentUser.getIdToken(/* forceRefresh */ true);
            this.publishLoading = true;
                fetch(process.env.VUE_APP_BOOKING_FUNCTION, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + idToken
                    },
                    body: JSON.stringify(details)
                }).then(res => {
                    this.publishLoading = false;
                    if(res.ok){
                        this.dismissCountDown = this.dismissSecs;
                    }else{
                        this.showDismissableAlert = true;
                        this.errorText = res.status + " " + res.statusText;
                    }
                }).catch(err => {
                    this.dismissCountDown = this.dismissSecs;
                    this.errorText = err;

                })
            
        },
        setLoadingStatus(status){
            this.publishLoading = status
        },
        createJSON(){
            return {
                "type": this.publishType,
                "date": this.date,
                "slots": this.timeSlots
            }
        },
        removeTime(time){
            
            this.timeSlots = this.timeSlots.filter(slot => slot != time);
            this.items = this.items.filter(slot => slot.tid != time);
            this.$refs.table.refresh();
            
        }, 
        removeAllTimes(){
            this.timeControls = false;
            this.timeSlots = [];
            this.items = [];
        },
        bookTime(time){
            this.$store.dispatch('SET_BOOKED_STATUS', {date: this.date, time: time});
        }
    },
    computed: {
        isTimesFilled(){
            return !this.interval && this.startTime && this.endTime && this.date
        }
    },
    watch: {
        startTime: function(){
            if(this.startTime) this.startFilled = true;
            else this.startFilled = false;
        },
        endTime: function() {
          if(this.endTime) this.endFilled = true;
          else this.endFilled = false;  
        },
        date: function() {
            
             if(this.date != ""){
                this.$store.dispatch("GET_BOOKING", this.date);
                this.$store.watch(() => this.$store.getters.getBooking, () => {
                    this.booking = this.$store.getters.getBooking;
                    })
             }
        },
        booking: function(){
            this.removeAllTimes();
            
            if(this.booking){
                this.booking.forEach(element => {
                    this.timeSlots.push(element.start);
                    this.items.push({
                        'datum': this.date,
                        'tid': element.start,
                        'bokad': element.booked
                    });
                });
                this.$refs.table.refresh();
            }
        }
    }
}
</script>
<style scoped>
</style>