import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VCalendar from 'v-calendar';
import { auth } from './api/firebase.js'

import { BootstrapVue } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

Vue.use(BootstrapVue, VCalendar, {
	componentPrefix: 'vc',
});

// new Vue({
//   store,
//   router,
//   render: h => h(App)
// }).$mount('#app')

let app;
auth().onAuthStateChanged((user) => {
	if (!app) {
		app = new Vue({
			router,
			store,
			render: h => h(App)
		}).$mount("#app");
	}
	
	if(user){
		
		store.dispatch('LOGIN_USER', user);
	}else{
		store.dispatch('LOGOUT_USER');
	}

});