<template >
    <div>
        <b-sidebar
            id="sidebar-1"
            title="Sidebar"
            bg-variant="dark"
            text-variant="light"
            width="200px"
            shadow
            visible
            noHeader
            :no-close-on-route-change="true"
        >
            <div class="px-3 py-2">
                <h4 id="sidebar-no-header-title">Däckpatrullen Management</h4>
                <nav class="mb-3">
                    <b-nav vertical pills justified>
                        <b-nav-item
                            active-class="active"
                            class="nav-link"
                            v-for="routes in links"
                            :key="routes.id"
                            :to="routes.path"
                        >
                            {{ routes.name }}
                        </b-nav-item>
                    </b-nav>
                </nav>
                
            </div>
            <div class="d-flex bg-dark text-light align-items-center px-3 py-2" style="position:absolute; bottom:0px;">  
                <b-button size="lg" @click="signOut()"><i class="fa fa-sign-out" aria-hidden="true"></i> Logga ut</b-button>
                
            </div>
        </b-sidebar>
    </div>
</template>
<script>
import { logOut } from '../api/firebase.js'

export default {
    name: "Temp",
    data() {
        return {
            links: [
                {
                    id: 0,
                    path: "/hem",
                    name: "Hem",
                },
                {
                    id: 1,
                    path: "/bokning",
                    name: "Bokningar",
                },
                {
                    id: 2,
                    path: "/statistik",
                    name: "Statistik",
                }
            ],

        };
    },
    methods: {
        signOut(){
            this.$router.go();
            logOut();
        }
    },
};
</script>
<style lang="">
</style>