import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Booking from '../views/Booking'
import Stats from '../views/Stats'
import Home from '../views/Home'
import Temp from '../views/Temp'
import { auth } from '../api/firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    redirect: { name: 'login'}
  },
  {
    path: '/bokning',
    name: 'Bokning',
    component: Booking,
    meta: {
      authenticated: true,
    }
  },
  {
    path: '/statistik',
    name: 'Statistik',
    component: Stats,
    meta: {
      authenticated: true,
    }
  },
  {
    path: '/temp',
    name: 'Temp',
    component: Temp,
    meta: {
      authenticated: true,
    }
  },
  {
    path: '/hem',
    name: 'hem',
    component: Home,
    meta: {
      authenticated: true,
    }
  },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

router.beforeEach((to, from, next) => {
  
  const requireAuth = to.matched.some(record => record.meta.authenticated);
  const isLoggedIn = auth().currentUser;
  
  if(requireAuth && !isLoggedIn){
    next('/login')
  }else{
    next();
  }
});
export default router
