import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/database';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBQu3dTqKbRLAVzSGn16ISLx3wSMl5rFj0",
    authDomain: "dackpatrullen-vue-2.firebaseapp.com",
    databaseURL: "https://dackpatrullen-vue-2-default-rtdb.firebaseio.com",
    projectId: "dackpatrullen-vue-2",
    storageBucket: "dackpatrullen-vue-2.appspot.com",
    messagingSenderId: "536811432140",
    appId: "1:536811432140:web:2463dca6dc7e68d0977a69",
    measurementId: "G-RTPN9D5FR9"
  };
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const analytics = firebase.analytics();
const database = firebase.database();
const auth = firebase.auth;

const fireLogin = (email, pass) => {
    return auth().signInWithEmailAndPassword(email, pass);
}
const forgotPassword = (email) => {
    return auth().sendPasswordResetEmail(email);
}
const logOut = () => {
    auth().signOut();
}

export {
    analytics,
    database,
    auth,
    fireLogin,
    forgotPassword,
    logOut
}