import Vue from 'vue'
import Vuex from 'vuex'
import { database } from '../api/firebase.js'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null, //User info
        loggedIn: false, 
        booking: [],  // Contains booking details for given date
        databaseLoadingRequest: false,  // Status for database loading
    },
    mutations: {
        setUser(state, user){
            state.user = user;
        },
        setLoggedIn(state, status){
            state.loggedIn = status
        },
        setBooking(state, data){
            state.booking = data;
        },
        setDatabaseLoadingStatus(state, status){
            state.databaseLoadingRequest = status;
        },
    },
    actions: {
        LOGIN_USER(state, user){
            
            state.commit('setUser', user);
            state.commit('setLoggedIn', true);
        },
        LOGOUT_USER(state){
            
            state.commit('setUser', null);
            state.commit('setLoggedIn', false);
        },
        /**
         * Retrieves bookings from database, also sets loading status
         * @param {*} state 
         * @param {*} date 
         */
        GET_BOOKING(state, date) {
            let loadingLock = true
            state.commit('setDatabaseLoadingStatus', true)
            console.log('getting bookings');
            // make loading seem more realistic
            setTimeout(() => {
                loadingLock = false;
                state.commit('setDatabaseLoadingStatus', false);
            }, 300);

            database.ref().child('booking/' + date).on('value', snap => {
                state.commit('setBooking', snap.val());
                if (!loadingLock) {
                    state.commit('setDatabaseLoadingStatus', false);
                }
            })
        },
        /**
         * Enables a inverse/toggle for time, to on the fly make a time booked or unbooked
         * @param {*} state 
         * @param {Object} param1 object containing date and time
         */
        async SET_BOOKED_STATUS(state, {date, time}) {
            let index = 0;
            let currentStatus;
            await database.ref().child('booking/' + date).on('value', snap => {
                let data = snap.val();
                for(let i = 0; i < data.length; i++){
                    if(data[i].start === time) index = i;
                }
                currentStatus = !data[index].booked;
                time = data[index].start;
            })
            
            const updates = {};            
            
            updates['booking/' + date + '/' + index] = {
                start: time,
                booked: currentStatus
            }

            await database.ref('availableDates/' + date).get().then(snap => {
                let numberSlots = snap.val();
                if (currentStatus) numberSlots--;
                else numberSlots++;
                updates['availableDates/' + date] = numberSlots;
            });
            
            database.ref().update(updates);
        }
    },
    getters: {
        getLoginStatus(state){
            return state.loggedIn;
        },
        getBooking(state){
            return state.booking;  
        },
        getDatabaseLoadingStatus(state){
            return state.databaseLoadingRequest;
        }
    },
    modules: {
    }
})
